import { FC } from 'react';
import { Popover } from "antd";

import { ReactComponent as AppsIcon } from "assets/svg/appSwitcher.svg";
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg";
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

import instagramSrc from "assets/images/social/instagram-w.svg";
import facebookSrc from "assets/images/social/facebook-w.svg";
import xSrc from "assets/images/social/x-w.svg";
import youtubeSrc from "assets/images/social/youtube-w.svg";
import linkedinSrc from "assets/images/social/linkedin-w.svg";
import tiktokSrc from "assets/images/social/tiktok-w.svg";

import {
  Box,
  AppsList,
  AppItem,
  AppLink,
  AppItemTitle,
  Footer,
  Social,
  Link,
  IconLink,
  Toggle
} from './styled';

// @ts-ignore
import customStyles from './global.module.scss';

// @ts-ignore
import { useProject, useAllocatorAccess } from "utils/utility";

// @ts-ignore
import { ALLOCATOR_TOOL } from "utils/routes";

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'project-planner-36424-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      gpsURL: 'goal-setting-workbo-37-staging.botics.co'
    }
  }
  else if (location.includes('dev.apps.arootah.com')) {
    return {
      hcURL: 'dev.app.arootah.com',
      gpsURL: 'dev.goals.arootah.com'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    gpsURL: 'goals.arootah.com',
  }
}

interface IProps {
  app: string;
}

const AppSwitcher: FC<IProps> = ({ app }) => {
  const { project } = useProject();
  const isAllocatorUser = useAllocatorAccess();

  const activeProject = project || app || null;

  const { hcURL, gpsURL } = getPath();

  return (
    <Popover
      overlayClassName={customStyles.popover}
      placement="bottomLeft"
      content={
        <Box>
          <AppsList>
            <AppLink href={`https://${gpsURL}`}>
              <LogoGPS />
              <AppItemTitle>Goal Manager</AppItemTitle>
            </AppLink>

            <AppLink href={`https://${gpsURL}/time-manager`}>
              <LogoAPM />
              <AppItemTitle>Time Manager</AppItemTitle>
            </AppLink>

            <AppLink href={`https://${hcURL}`}>
              <LogoHC />
              <AppItemTitle>Habit Manager</AppItemTitle>
            </AppLink>

            <AppItem to={isAllocatorUser ? `/${ALLOCATOR_TOOL}` : "/decision-manager"} $active={activeProject === 'decision-manager'}>
              <LogoDM />
              <AppItemTitle>{isAllocatorUser ? "Allocator Tool" : "Decision Manager"}</AppItemTitle>
            </AppItem>

            <AppItem to="/project-planner" $active={activeProject === 'project-planner'}>
              <LogoPP />
              <AppItemTitle>Project Manager</AppItemTitle>
            </AppItem>
          </AppsList>

          <Footer>
            <Social>
              <IconLink href="https://www.instagram.com/arootahcoach/" title="Instagram" target="_blank">
                <img src={instagramSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.facebook.com/arootahcoach" title="Facebook" target="_blank">
                <img src={facebookSrc} alt="" />
              </IconLink>

              <IconLink href="https://x.com/arootahcoach" title="X (Twitter)" target="_blank">
                <img src={xSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.youtube.com/channel/UCq5fhJkfKRUGmjzTvMsFU-w" title="Youtube" target="_blank">
                <img src={youtubeSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.linkedin.com/company/arootahcoaching/?ref=website" title="Linkedin" target="_blank">
                <img src={linkedinSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.tiktok.com/@arootahcoach" title="TikTok" target="_blank">
                <img src={tiktokSrc} alt="" />
              </IconLink>
            </Social>

            <Link href="https://arootah.com" target="_blank">www.arootah.com</Link>
          </Footer>
        </Box>
      }
      trigger="click"
    >
      <Toggle $project={activeProject}>
        <AppsIcon />
      </Toggle>
    </Popover>
  );
}

export default AppSwitcher;
