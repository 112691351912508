import { useState, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import moment from "moment";

import { Wrapper, Head, HeadItem, Body } from "./styled";

import Category, { AddCategory } from "./Category";
import AdjustWeight from "../AdjustWeight";

import { IPlan } from "./@types";

// @ts-ignore
import { changeCategoryOrder } from "modules/actions/PlanActions";

// import expandIcon from './expand.svg';
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { ReactComponent as ExpandIcon } from "./expand.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";

// @ts-ignore
import { createPlanCategory } from "modules/actions/PlanActions";

interface IActionItems {
  planDetails: IPlan;
  isShared: boolean;
  isShort: boolean;
  isExpandAllNotes: boolean;
}

const ActionItems: FC<IActionItems> = ({
  planDetails,
  isShared,
  isShort,
  isExpandAllNotes,
}) => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState<
    { id: string; isExpand: boolean }[]
  >([]);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [isAdjustWeightActive, setIsAdjustWeightActive] = useState(false);

  useEffect(() => {
    const updatedActiveKey = planDetails.categories.map((category) => {
      const existingKey = activeKey.find(
        (keyItem) => keyItem.id === category.id.toString()
      );
      return existingKey || { id: category.id.toString(), isExpand: true };
    });

    // Ensure we're not losing any existing keys that might not be in the current planDetails.categories
    const existingIds = planDetails.categories.map((category) =>
      category.id.toString()
    );
    const preservedKeys = activeKey.filter((keyItem) =>
      existingIds.includes(keyItem.id)
    );

    setActiveKey([...new Set([...updatedActiveKey, ...preservedKeys])]);
  }, [planDetails]);

  const handleToggle = (id: string) => {
    setActiveKey(
      activeKey.map((keyItem) =>
        keyItem.id === id
          ? { ...keyItem, isExpand: !keyItem.isExpand }
          : keyItem
      )
    );
  };

  const handleAddCategory = () => {
    setIsAddCategory(!isAddCategory);
  };

  const handleCategoryCreate = (value: string) => {
    if (!value) {
      setIsAddCategory(false);
      return;
    }

    const payload = {
      name: value,
      start_line: moment(planDetails?.start_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      dead_line: moment(planDetails?.dead_line ?? new Date()).format(
        "YYYY-MM-DD"
      ),
      project_plan: planDetails?.id,
    };

    dispatch(createPlanCategory(payload, handleCategoryCreateCallback));
  };

  const handleCategoryCreateCallback = () => {
    setIsAddCategory(false);
  };

  const handleCollapseExpandAll = () => {
    if (
      activeKey.filter((keyItem) => keyItem.isExpand).length ===
      planDetails.categories.length
    ) {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: false }))
      );
    } else {
      setActiveKey((prev) =>
        prev.map((keyItem) => ({ ...keyItem, isExpand: true }))
      );
    }
  };

  const handleAdjustWeightToggle = () => {
    setIsAdjustWeightActive(!isAdjustWeightActive);
  };

  const onDragEndHandle = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside any list
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // If it's in the same category, reorder items within the category
    if (sourceCategoryId === destinationCategoryId) {
      handleReorderWithinCategory(
        sourceCategoryId,
        source.index,
        destination.index
      );
    } else {
      // If moved to a different category, move between categories
      handleMoveBetweenCategories(
        sourceCategoryId,
        destinationCategoryId,
        source.index,
        destination.index
      );
    }
  };

  // Reordering within the same category
  const handleReorderWithinCategory = (
    categoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];
    const category = updatedCategories.find(
      (cat) => cat.id.toString() === categoryId
    );

    if (!category) return;

    const reorderedItems = Array.from(category.action_items);
    const [movedItem] = reorderedItems.splice(sourceIndex, 1);
    reorderedItems.splice(destinationIndex, 0, movedItem);

    // Update the category with the new order of action items
    category.action_items = reorderedItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  // Moving between different categories
  const handleMoveBetweenCategories = (
    sourceCategoryId: any,
    destinationCategoryId: any,
    sourceIndex: any,
    destinationIndex: any
  ) => {
    const updatedCategories = [...planDetails.categories];

    const sourceCategory = updatedCategories.find(
      (cat) => cat.id.toString() === sourceCategoryId
    );
    const destinationCategory = updatedCategories.find(
      (cat) => cat.id.toString() === destinationCategoryId
    );

    if (!sourceCategory || !destinationCategory) return;

    const sourceItems = Array.from(sourceCategory.action_items);
    const [movedItem] = sourceItems.splice(sourceIndex, 1);

    const destinationItems = Array.from(destinationCategory.action_items);
    destinationItems.splice(destinationIndex, 0, movedItem);

    // Update the action items for source and destination categories
    sourceCategory.action_items = sourceItems;
    destinationCategory.action_items = destinationItems;

    // Send updated actions to the API
    sendActionsToAPI(updatedCategories);
  };

  const sendActionsToAPI = (updatedCategories: any) => {
    // Construct the payload based on the updated categories
    const actions: any = [];

    updatedCategories.forEach((category: any) => {
      category.action_items.forEach((item: any, index: number) => {
        actions.push({
          id: item.id,
          order_id: index,
          category: category.id,
        });
      });
    });

    const payload = { actions };

    dispatch(changeCategoryOrder(payload, planDetails?.id));
  };

  return (
    <Wrapper>
      <Head $color="#1C8B7E" isShort={isShort}>
        <HeadItem>
          <input type="checkbox" />
        </HeadItem>

        <HeadItem>
          <ExpandIcon
            className="cursor-pointer"
            onClick={handleCollapseExpandAll}
          />
        </HeadItem>

        <HeadItem>
          Category & Action Items
          {!isShared && (
            <span
              onClick={handleAddCategory}
              className="add-catrgory"
              style={{
                opacity: isAddCategory ? 0.5 : 1,
                pointerEvents: isAddCategory ? "none" : "auto",
                cursor: isAddCategory ? "not-allowed" : "pointer",
              }}
            >
              <PlusIcon />
              Add Category
            </span>
          )}
        </HeadItem>
        {/* <HeadItem $center>Weekly Duration</HeadItem> */}
        <HeadItem>Weight</HeadItem>
        <HeadItem>Plan Progress</HeadItem>
        <HeadItem>Status</HeadItem>
        <HeadItem>Measurement</HeadItem>
        <HeadItem>Start Date</HeadItem>
        <HeadItem>Due Date</HeadItem>
        <HeadItem>Duration</HeadItem>
        <HeadItem $center>Assignee</HeadItem>
        <HeadItem></HeadItem>
        {/* {isShort ? null : <HeadItem $center>Results Progress</HeadItem>} */}
        {/* <HeadItem $center>Person Responsible</HeadItem> */}
        {/* <HeadItem $center>Start Date</HeadItem>
        <HeadItem $center>Due Date</HeadItem> */}
        {/* {isShort ? null : <HeadItem $center>Start Value</HeadItem>}
        {isShort ? null : <HeadItem $center>Current Value</HeadItem>}
        {isShort ? null : <HeadItem $center>Target Value</HeadItem>} */}
      </Head>

      <Accordion
        as={Body}
        activeKey={activeKey
          .filter((item) => item.isExpand)
          .map((item) => item.id)}
      >
        {isAddCategory ? (
          <AddCategory
            isShared={isShared}
            isShort={isShort}
            setIsAddCategory={setIsAddCategory}
            onCreate={handleCategoryCreate}
          />
        ) : null}
        <DragDropContext onDragEnd={onDragEndHandle}>
          {planDetails.categories.map((item) => (
            <Droppable droppableId={item.id.toString()} key={item.id}>
              {(provided) => (
                <Category
                  data={item}
                  additionalData={{
                    plan_start_date: planDetails.start_line,
                    plan_end_date: planDetails.dead_line,
                  }}
                  isActive={activeKey
                    .filter((a) => a.isExpand)
                    .map((a) => a.id)
                    .includes(item.id.toString())}
                  onToggle={handleToggle}
                  onAdjustWeight={handleAdjustWeightToggle}
                  isShared={isShared}
                  isExpandAllNotes={isExpandAllNotes}
                  isShort={isShort}
                  key={item.id}
                  provided={provided}
                  isAddCategory={isAddCategory}
                  setIsAddCategory={setIsAddCategory}
                />
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Accordion>

      <AdjustWeight
        isAdjustWeightActive={isAdjustWeightActive}
        onClose={handleAdjustWeightToggle}
      />
    </Wrapper>
  );
};

export default ActionItems;
